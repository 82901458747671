<template>

    <XDataTable v-model:filters="filters" v-model:paginator="paginator" :total-rows-count="totalRowsCount"
                @needGetTableItems="getTableRows(true)">

        <template #toolBarRightSide>
            <Button class="p-button p-button-outlined mr-3"
                    label="Отправить запрос на изменение сведений об организации в ГАС"
                    @click="UpdateOrganizationDataRequest"/>
            <Button class="p-button p-button-outlined mr-3" label="Запросить исторические сведения ГАС"
                    @click="requestGas('history')"/>
            <Button class="p-button p-button-outlined" label="Запросить актуальный справочник ГАС"
                    @click="requestGas('actual')"/>
        </template>

        <template #DataTable>

            <DataTable ref="Table" :loading="tableLoading" :paginator="false" :rows="20" :value="tableItems"
                       lazy
                       removableSort
                       responsiveLayout="scroll"
                       selectionMode="single"
                       @sort="sort"
                       @row-click="tableItemsClick($event)">

                <Column :sortable="true" field="time_created">
                    <template #header>
                        <SearchDate v-model="filters['time_created']" label="Контейнер отправлен"/>
                    </template>
                    <template #body="slotProps">
                        {{ unixTimeStampToDateTime(slotProps.data?.['time_created']) }}
                    </template>
                </Column>

                <Column :sortable="true" style="width:15%">
                    <template #header>
                        <SearchSelect v-model="filters['delivery_status']"
                                      :options="deliveryStatuses"
                                      label="Статус"
                                      option-label="key"
                                      option-value="value"/>
                    </template>
                    <template #body="slotProps">
                        <span>{{
                                getObjectFromArrayByPropertyValue(deliveryStatuses, 'value', slotProps.data.delivery_status).key || 'Статус неизвестен'
                            }}</span>
                    </template>
                </Column>
                <Column :sortable="true" field="request_guid" filter-field="request_guid">
                    <template #header>
                        <SearchInput v-model="filters['request_guid']" label="Идентификатор запроса"/>
                    </template>
                </Column>
                <Column :sortable="true" field="container_guid" filter-field="container_guid">
                    <template #header>
                        <SearchInput v-model="filters['container_guid']" label="Идентификатор контейнера"/>
                    </template>
                </Column>
                <Column :sortable="true" field="type" filter-field="type">
                    <template #header>
                        <SearchInput v-model="filters['type']" label="Тип запроса"/>
                    </template>
                </Column>

            </DataTable>

        </template>

    </XDataTable>

    <RequestReportCard ref="RequestReportCard" @okBtnClicked="onRequestCardOkBtnClicked"/>
    <RequestCard ref="RequestCard" :deliveryStatuses="deliveryStatuses" page-url="gas-requests"/>

    <ModalInputText ref="justificationPrompt" @okBtnClicked="justificationPromptOkClicked"/>

</template>

<script>

import {
  DataFilters,
  Helper,
  ModalInputText,
  SearchDate,
  SearchInput,
  SearchSelect,
  XDataTable
} from "@xnpmpackages/xcomponents";
import RequestReportCard from "./sppr_requests/RequestReportCard";
import RequestCard from "./sppr_requests/RequestCard";


export default {
    name: "GasRequests",
    data() {
        return {

            tableLoading: true,
            timers: {
                tableItems: null,
            },

            tableItems: null,

            nowSelectedReport: null,

            reportAddViewLoading: false,

            loadingElement: null,

            filters: {},
            totalRowsCount: 0,
            paginator: {
                page: 1,
                limit: 20,
            },


            deliveryStatuses: [
                {key: 'Отправлен', value: 0},
                {key: 'Принят организатором', value: 1},
                {key: 'Не принят организатором', value: 2},
                {key: 'Предоставлен полный ответ', value: 3},
                {key: 'Предоставлен частичный ответ', value: 4},
                {key: 'В предоставлении данных отказано', value: 5},
                {key: 'Все', value: null}]

        }
    },
    mixins: [Helper, DataFilters],
    methods: {

        async justificationPromptOkClicked(justification) {

            try {

                this.$xapi.showBlockModal('Формирование контейнера...');

                await this.$xapi.post(
                    '/gas-requests',
                    {
                        action: 'UpdateOrganizationDataRequest',
                        justification: justification,
                    },
                );

                this.$xapi.xnotify('Организатору отправлен запрос');
                this.getTableRows(true);

            } finally {
                this.$xapi.hideBlockModal();
            }

        },


        async UpdateOrganizationDataRequest() {

            await this.$refs.justificationPrompt.show('', 'Укажите официальное основание для изменений данных по участнику', 'Организатору будет отправлен контейнер с запросом на изменение сведений об организации.', 'Отправить запрос');

            /*this.$confirm.require({
                message: 'Организатору будет отправлен контейнер с запросом на изменение сведений об организации. Продолжить?',
                header: 'Подтверждение',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Да',
                rejectLabel: 'Нет',
                accept: async () => {

                    try {

                        this.$xapi.showBlockModal('Формирование контейнера...');

                        await this.$xapi.post(
                            '/gas-requests',
                            {
                                action: 'UpdateOrganizationDataRequest',
                            },
                        );

                        this.$xapi.xnotify('Организатору отправлен запрос');
                        this.getTableRows(true);

                    } finally {
                        this.$xapi.hideBlockModal();
                    }

                },
                reject: async () => {
                    this.$xapi.hideBlockModal();
                },

            });*/

        },

        async requestGas($referenceType) {

            this.$confirm.require({
                message: 'Организатору будет отправлен контейнер с запросом на получение справочника. Продолжить?',
                header: 'Подтверждение',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Да',
                rejectLabel: 'Нет',
                accept: async () => {

                    try {

                        this.$xapi.showBlockModal('Формирование контейнера...');

                        await this.$xapi.post(
                            '/gas-requests',
                            {
                                action: 'requestGas',
                                referenceType: $referenceType,
                            },
                        );

                        this.$xapi.xnotify('Организатору отправлен запрос');
                        this.getTableRows(true);

                    } finally {
                        this.$xapi.hideBlockModal();
                    }

                },
                reject: async () => {
                    this.$xapi.hideBlockModal();
                },

            });

        },

        /* ======================================== */

        sort() {
            this.getTableRows(true);
        },

        async tableItemsClick($event) {

            await this.$refs.RequestCard.show(this.copyObjectByJSON($event.data), this.tableItems);

        },

        onRequestCardOkBtnClicked() {
          this.$refs.TablePage.getTableRows(true);
        },

        async getTableRows($changeStateToLoading) {

            if ($changeStateToLoading)
                this.tableLoading = true;

            clearTimeout(this.timers.tableItems);


            return await this.$xapi.post(
                '/gas-requests',
                {
                    action: 'gettableItems',
                    filter: this.filters,
                    paginator: this.paginator,
                    sort: {[this.$refs.Table?.d_sortField]: this.$refs.Table?.d_sortOrder}
                })
                .then((response) => {
                    this.tableItems = response.data.tableItems;
                    this.totalRowsCount = response.data.rows_count;
                    //this.timers.tableItems = setTimeout(this.getTableRows, 10000);
                    return this.tableItems;
                })
                .finally(() => {
                    this.tableLoading = false;
                });

        },

    },
    async mounted() {
        await this.getTableRows();
    },
    components: {
        ModalInputText,
        RequestCard,
        RequestReportCard,
        XDataTable,
        SearchInput,
        SearchSelect,
        SearchDate
    }
}
</script>

<style scoped>

</style>